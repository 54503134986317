.item {
  display: grid;
  grid-template:
    "job job star  "
    "email  email  date  ";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  width: 80%;
  margin: 0.5rem 0;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid rgba(205, 216, 253, 1);
  max-height: 206px;
  min-height: 190px;
  gap: 5px;
}
.item:not(.item-selected):hover {
  border: solid 1px #4c6ce2;
  cursor: pointer;
}
.item-selected {
  border: solid 1px #4c6ce2;
}
.job-name {
  grid-area: job;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 7px;
}
.job {
  font-size: 25px;
  line-height: 22px;
  font-weight: 500;
  text-decoration: underline;
}
.name {
  display: flex;
  gap: 3px;
  line-height: 16px;
  font-weight: 600;
  font-size: 16px;
  padding: 4px;
}
.lu {
  border-radius: 10px;
  background-color: #d1fae5;
  padding: 3px 10px 3px 10px;
  font-size: 10px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: #047857;
  width: 60px;
  height: 28px;
}
.no-lu {
  border-radius: 10px;
  background-color: #fef3c7;
  padding: 3px 10px 3px 10px;
  font-size: 10px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: #b45309;
  width: 69px;
  height: 28px;
}
.type-contrat {
  grid-area: date;
  color: white;
  height: min-content;
  padding: 3px;
  border-radius: 6px;
}
.freelance {
  width: 97px;
  color: black;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: right;
}
.cdi {
  width: 97px;
  color: black;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: right;
}
.name {
  display: flex;
  gap: 3px;
}
.name span {
  line-height: 16px;
  font-weight: 600;
  font-size: 16px;
}
.email-location-source {
  grid-area: email;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.email {
  font-size: 13px;
  line-height: 13px;
  color: #8c8c8c;
  font-weight: 500;
}
.phone {
  font-size: 13px;
  line-height: 13px;
  color: #8c8c8c;
  font-weight: 500;
}
.source {
  font-size: 13px;
  line-height: 13px;
  color: #645959;
  font-weight: 500;
  text-decoration: underline;
}
.tags {
  grid-area: email;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 7px;
}
.user {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  margin: 0 3px;
  border: solid 1px #cdd8fd;
  padding: 4px 11px;
  border-radius: 20px;
  white-space: nowrap;
}
.date-type-button {
  grid-area: date;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  gap: 9px;
}
.cree-le {
  color: #047857;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  border-radius: 10px;
  background-color: #d1fae5;
  padding: 5px;
  width: 110px;
  height: 30px;
  line-height: 22px;
}
.old-cree-le {
  color: #b45309;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  border-radius: 10px;
  background-color: #fef3c7;
  padding: 5px;
  width: 110px;
  height: 30px;
  line-height: 22px;
  margin-left: 20px;
}
.star {
  grid-area: star;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  justify-content: right;
  gap: 15px;
}
.delete-cv {
  z-index: 10;
  width: min-content;
  cursor: pointer;
  color: rgb(71, 145, 255, 40%);
  width: 31px;
  height: 31px;
  border: none;
}
.save {
  z-index: 10;
  width: min-content;
  cursor: pointer;
  color: rgb(71, 145, 255, 40%);
  width: 31px;
  height: 31px;
}
.save.saved {
  color: rgb(71, 145, 255);
}
.users {
  grid-area: date;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.aussi-par {
  grid-area: date;
  margin-right: 4px;
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 10px;
}
#savedby {
  grid-area: date;
  font-size: 10px;
  line-height: 10px;
  color: #4c6ce2;
  margin-bottom: 10px;
}
.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-content {
  position: absolute;
  z-index: 10;
  width: max-content;
  left: 10%;
  transform: translateX(-50%);
  background-color: #d2d2d2;
  color: black;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400px;
  text-align: center;
  padding: 5px;
  margin-top: 5px;
  border-radius: 6px;
}
.tooltip-content::after {
  content: "";
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #d2d2d2 transparent;
}
.tags-popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 2; 
}
.more-tags {
  position: relative;
}