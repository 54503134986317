#header {
  grid-area: head;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
}
#logo-visian {
  position: relative;
  width: 148.54px;
  height: 34.94px;
  padding-left: 15px;
  cursor: pointer;
}
#nombre-cvs {
  font-style: Bold;
  font-size: 22px;
  line-height: 22px;
  line-height: 28.8px;
  font-weight: 700;
  color: #231656;
  padding-bottom: 14px;
}
#username {
  position: relative;
  font-style: semiBold;
  font-size: 18px;
  line-height: 96%;
  min-width: 85px;
  padding: 7px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#_username {
  margin-left: 3px;
  color: #4791ff;
  text-decoration: underline;
}
#sign-out {
  font-style: semiBold;
  font-size: 35px;
  color: #4791ff;
  margin-left: 10px;
  cursor: pointer;
}
#haut-droite {
  display: flex;
  align-items: center;
  padding-bottom: 14px;
  margin-right: 15px;
}
.menu-option.selected {
  color: #4791ff;
}
#menu {
  position: relative;
}
#menu-options {
  position: absolute;
  top: 160%; 
  left: 50%; 
  transform: translateX(-50%);
  z-index: 1; 
  display: flex;
  flex-direction: column;
  border: solid 1px #a0a3bd;
  border-radius: 2px;
  padding: 5px;
}
.menu-option {
  cursor: pointer;
  transition: background-color 0.3s;
  color: black;
  margin-bottom: 5px;
  text-decoration: underline;
}
.menu-option:hover {
  cursor: pointer;
  transition: background-color 0.3s;
  color: #4791ff;
}
#menu:hover #menu-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 160%;
  left: 50%;
  transform: translateX(-50%);
}
#logout-popup {
  position: absolute;
  top: 130%;
  left: 50%; 
  transform: translateX(-50%);
  z-index: 1;
  background-color: #fff;
  font-size: 15px;
  cursor: pointer;
}

#username:hover #logout-popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 130%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 15px;
  cursor: pointer;
  color: #4791ff;
}
.iconMenuD {
  cursor: pointer;
}
