#barreUsers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.user-recherche {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.add-delete-button-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.add_button_user {
  padding: 8px;
  border: none;
  color: white;
  background-color: #4c6ce2;
  border-radius: 8px;
  font-size: 12px;
}
.delete_button_user {
  padding: 8px;
  border: none;
  color: white;
  background-color: #4c6ce2;
  border-radius: 8px;
  font-size: 12px;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.form-user-container {
  display: flex;
  flex-direction: column;
}
.title-container-add-user {
  color: white;
  background-color: #4c6ce2;
  padding: 7px;
}
.label-container {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  gap: 20px;
}
.add-user-identity {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.add-user-pwd {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.close-form-user {
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: black;
  background-color: white;
  border: solid black 1px;
  border-radius: 0px;
  padding: 5px;
}
.save-form-user-button {
  margin-top: 27px;
  color: white;
  background-color: #4c6ce2;
  border: none;
  border-radius: 8px;
  padding: 5px;
}