@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");
#message-aucun-cv {
  margin-top: 40px;
}

#content {
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
}

#colonne-page {
  font-family: "Inter", sans-serif;
  display: grid;
  grid-template:
    "head  head head"
    "tabs  tabs content"
    "sb    sb	content"
    "liste drag content"
    "nav   drag content";
    /*"tabhistorique tabhistorique tabhistorique"
    "barrehistorique barrehistorique barrehistorique"*/
  grid-template-columns: 1fr 5px min-content;
  grid-template-rows: auto auto auto 1fr min-content auto;
  height: 100vh;
  background-color: white;
}
#liste-cvs {
  grid-area: liste;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background-color: white;
}
/* This styles the scrollbar track (the part the thumb moves along) */
#liste-cvs::-webkit-scrollbar-track {
  background-color: #f9f9f9; /* Light grey background */
  border-radius: 10px; /* Rounded corners for the track */
}

/* This styles the scrollbar thumb (the part that's draggable) */
#liste-cvs::-webkit-scrollbar-thumb {
  background-color: #bcbcbc; /* Dark grey thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 3px solid #f9f9f9; /* Creates a bit of margin between track and thumb */
}

/* This styles the scrollbar itself (including the thumb, track, and buttons) */
#liste-cvs::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* If you want to style the scrollbar buttons (arrows at the end of the scrollbar) */
#liste-cvs::-webkit-scrollbar-button {
  background-color: #dcdcdc;
}

/* If you want to style the scrollbar upon hovering */
#liste-cvs::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0; /* Darken the thumb a bit when hovered */
}
#footer {
  margin-top: 10px;
  margin-left: 30%;
  margin-bottom: 10px;
}
