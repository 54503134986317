#hi-recherche {
    grid-area: sb;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.hi-barrerecherche {
    background-color: #f2f4f8;
    height: 40px;
    border: none;
    border-radius: 12px;
    padding-left: 30px;
    margin-right: 55px;
}