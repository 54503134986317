@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");
#carre-page {
  --cust-blue: #4c6ce2;
}
#carre-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #a5b6f4;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 40px;
  width: 700px;
  height: 605px;
  margin: 5% auto;
  font-family: "Inter", sans-serif;
}
#logo-visian {
  margin-top: 18px;
  background-repeat: no-repeat;
  margin-bottom: 50px;
}
#fields {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
label {
  font-style: medium;
  font-size: "13px";
  line-height: "13px";
  font-weight: 500;
  color: #4b4b7e;
  margin-bottom: 2px;
}
#welcome {
  text-align: center;
  justify-content: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 36px;
  line-height: 74%;
  letter-spacing: -2px;
  color: #000000;
}

#carre-page input {
  background: #fafbfe;
  border: 1px solid #eae9ff;
  width: 100%;
  height: 32px;
}
#email {
  background: #fafbfe;
  border: 1px solid #eae9ff;
  color: #999fc8;
  padding-left: 5px;
  margin-bottom: 10px;
}
#mdp {
  border-radius: 4px;
  background: #fafbfe;
  border: 1px solid #eae9ff;
  padding-left: 5px;
  margin-bottom: 5px;
}
#se-connecter {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  padding: 10px 0;
  background: #4c6ce2;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 15px;
}

#mdp-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
#mdp-oublie {
  color: var(--cust-blue);
  font-size: 12px;
}
#contacter-yohan {
  color: var(--cust-blue);
  margin-bottom: 50px;
}
#error-message {
  color: red;
}
