#cv-page {
  grid-area: content;
  display: flex;
  flex-direction: column;
  min-width: 378px;
  padding-bottom: 5px;
  background-color: #f5f8fa;
  padding: 10px;
  border-radius: 15px;
  border: 1px white;
}
#job {
  font-size: 16px;
  font-weight: 500px;
  line-height: 12px;
  margin-top: 10px;
}
#job strong {
  text-decoration: underline;
  font-size: 23px;
}
#cv-page-header {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  gap: 1px 0px;
  grid-template-areas:
    "job close"
    "email fav"
    "tags butt";
  min-width: 378px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(205, 216, 253, 1);
}
#fav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid: fav;
  margin-top: 15%;
  /*margin-right: 20%;*/
  font-size: 24px;
  gap: 15px;
  color: #4c6ce2;
}
#job-name {
  display: flex;
  flex-direction: row;
  grid-area: job;
  margin-right: 10px;
}
#email_user {
  font-size: 16 px;
  line-height: 22px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}
#phone {
  font-weight: 600;
  size: 16px;
  line-height: 16px;
  margin-bottom: 5px;
}
#date {
  font-size: 16 px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 5px;
}
.tags {
  grid-area: tags;
  display: flex;
  margin-right: 10px;
}
#close-cv {
  grid-area: close;
  border: none;
  color: rgba(76, 108, 226, 1);
  width: 18.08px;
  height: 18.08px;
  justify-self: right;
  background-color: #f3f4f5;
  font-size: 30px;
  margin-right: 10px;
}
#visu-cv-container {
  grid-area: butt;
  display: flex;
  justify-content: flex-end;
  padding: 0 0px 10px 0;
}

#cv-content {
  overflow-y: auto;
  margin: 12px;
  padding-left: 10px;
  background-color: white;
  border-radius: 10px;
}
#email_phone {
  margin-top: 5px;
  grid-area: email;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: rgba(140, 140, 140, 1);
  margin-left: 10px;
}
#name {
  flex-grow: 1;
  font-size: 12px;
  line-height: 12px;
}

/* This styles the scrollbar track (the part the thumb moves along) */
#cv-content::-webkit-scrollbar-track {
  background-color: #f9f9f9; /* Light grey background */
  border-radius: 10px; /* Rounded corners for the track */
}

/* This styles the scrollbar thumb (the part that's draggable) */
#cv-content::-webkit-scrollbar-thumb {
  background-color: #bcbcbc; /* Dark grey thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 3px solid #f9f9f9; /* Creates a bit of margin between track and thumb */
}

/* This styles the scrollbar itself (including the thumb, track, and buttons) */
#cv-content::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* If you want to style the scrollbar buttons (arrows at the end of the scrollbar) */
#cv-content::-webkit-scrollbar-button {
  background-color: #dcdcdc;
}

/* If you want to style the scrollbar upon hovering */
#cv-content::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0; /* Darken the thumb a bit when hovered */
}

.save {
  z-index: 10;
  width: min-content;
  cursor: pointer;
  color: rgb(71, 145, 255, 40%);
  width: 31px;
  height: 31px;
}
.save.saved {
  color: rgb(71, 145, 255);
}
.display-tags {
  color: white;
  background-color: #4c6ce2;
  border-radius: 12px;
  border: none;
  width: 148px;
  height: 32px;
}
.tags-popup {
  z-index: 5;
  top: 35%;
}
.highlight-1 {
  background-color: #dbeafe;
}
.highlight-2 {
  background-color: #cffafe;
}
.highlight-3 {
  background-color: #828df380;
}
