#sb-container {
  grid-area: sb;
  /* width: 100%; */
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  height: 110px; /*valeur reel 110px*/
}
/*#search-icon {
    position: absolute;
    left: 10px;
    color: white;
    margin-right: 10px;
}*/
.saisie {
  flex-direction: row;
  background-color: white;
  height: 40px;
  margin-bottom: 15px;
  position: relative;
}
.p-inputtext {
  background-color: #f2f4f8;
  height: 40px;
  /*border: none;*/
  border-radius: 12px;
  padding-left: 30px;
  margin-right: 55px;
}
.p-inputtext:focus {
  background-color: white;
  height: 40px;
  /*border: none;*/
  border-radius: 12px 12px 0 0;
  padding-left: 30px;
  margin-right: 55px;
}
.search-button {
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 12px;
  background-color: #4c6ce2;
  color: white;
  font-size: 17px;
}
.reinitialiser {
  border: none;
  background-color: white;
  color: #5a5555;
  font-size: 14px;
  margin-left: 10px;
}
.search-icon-container {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  margin-top: 1px;
}
.search-icon {
  color: grey;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6b7280;
}
.p-multiselect-token {
  padding: 2px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: #4c6ce2;
}
.application-checkbox {
  margin-left: 10px;
}
#application-type,
#application-date,
#application-source,
#application-location {
  width: 150px;
  height: 30px;
  padding: 3px;
  margin-right: 10px;
}
.custom-white .p-multiselect-trigger {
  color: #6b7280;
}
.aff-non-vue {
  margin-top: 7px;
  font-size: 12px;
  margin-left: 13px;
  color: #5a5555;
}
.eye-slash {
  margin-top: 8px;
  margin-left: 10px;
  width: 16px;
  height: 16px;
}
.new-form {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.custom-select {
  color: #5a5555;
  margin-left: 15px;
  border: solid 1px #5a5555;
  border-radius: 8px;
  padding: 3px;
}
.select-triger {
  align-self: flex-start;
}
.form-multi-select {
  display: flex;
  flex-direction: row;
}
.field {
  display: flex;
  justify-content: center;
  /*background: var(--surface-card);*/
  color: #5a5555;
  border: solid 1px #c8c6c6;
  border-radius: 8px;
  padding: 3px;
  font-size: 14px;
}
.field:not(.champSpecial) {
  margin-left: 15px;
}
.field label {
  margin-right: 5px;
}
.field :right {
  margin-right: 10px;
}
.nn-v {
  display: flex;
  margin-left: 10px;
}
.nn-v input {
  margin-top: 3px;
}
/*div.p-multiselect-panel {
  background-color: #ffffff;
  border: solid 1px lightgray;
}*/
.p-multiselect-close {
  margin-top: 6px;
  margin-right: 10px;
  margin-bottom: 6px;
}
ul[role="listbox"] {
  /*background-color: #ffffff;*/
  border-radius: 12px;
}
li.p-multiselect-item {
  width: 268px;
  height: 36px;
}
li.p-multiselect-item.p-highlight {
  background-color: #e5eaf9;
  color: #4c6ce2;
}
.p-multiselect.p-component.p-inputwrapper.p-inputwrapper-filled {
  
  background-color: #4c6ce2; 
  border: 1px solid #e5eaf9;
  color: white; 
  
}
.p-multiselect.p-component.p-inputwrapper.p-inputwrapper-filled.p-multiselect-trigger {
  color: #4c6ce2; 
}
div.p-checkbox {
  margin-left: 7px;
  margin-right: 7px;
}
.form-multi-select {
  height: 25px;
}
.numberOfSelected {
  font-weight: bold;
  color: #4c6ce2;
  background-color: white;
  border-radius: 50%;
  padding: 0.25em 0.5em;
  display: inline-block;
  font-size: 12px;
  margin-left: 15px;
}
.LabelSelectedItem {
  display: flex;
}
.p-multiselect.p-component
  .p-inputwrapper.p-inputwrapper-filled:has(.p-multiselect-trigger) {
  background-color: white;
}
#application-date_selectall {
  display: none;
}
#application-date_selectall + label::before {
  content: "Sélection unique";
  display: block;
  padding: 8px;
  background-color: #f9fafb;
  cursor: not-allowed;
  pointer-events: none;
}
.p-multiselect-select-all-label::before {
  content: "Tout sélectionner";
  display: inline-block;
  margin-left: 8px;
}
/* Style pour aligner verticalement la checkbox et le label */
.p-checkbox-box,
.p-multiselect-select-all-label {
  vertical-align: middle;
}
.p-autocomplete-panel {
  margin-top: -1px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #4c6ce2;
  border-radius: 0 0 12px 12px;
  background-color: white;
  box-shadow: 3px 3px 1px rgb(191, 213, 244, 1), -3px 3px 1px rgba(191, 213, 244, 1), 0 0 1px rgba(191, 213, 244, 1); 
}
.p-autocomplete-items {
  background-color: white;
  border-radius: 12px;
}
.p-autocomplete-item {
  border-radius: 12px;
}
.p-autocomplete-item:hover {
  background-color: white;
  color: #4c6ce2;
}
.p-inputwrapper-focus {
  box-shadow: none;
}
.p-autocomplete-item.p-highlight {
  background-color: #f2f4f8;
  color: #4c6ce2;
}