.tag {
  display: flex;
  flex-wrap: nowrap;
  height: 28px;
  border-radius: 10px;
  white-space: nowrap;
  color: #0e7490;
  flex-direction: row;
  font-size: 12px;
}
.keyword {
  /*width: auto;*/
  height: 28px;
  border-radius: 10px 0px 0px 10px;
  /*padding-top: 3px;
    padding-left: 8px;*/
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 7px;
  padding-right: 7px;
}
.keywordOccurences {
  width: 32px;
  height: 28px;
  border-radius: 0px 10px 10px 0px;
  align-items: center;
  /*padding-top: 3px;
    padding-left: 8px;*/
  display: flex;
  justify-content: center;
}
.highlight {
  background-color: #cffafe;
  font-weight: bold;
}
.highlight-0 .keyword,
.highlight-4 .keyword,
.highlight-8 .keyword,
.highlight-12 .keyword {
  background-color: #cffafe;
}
.highlight-1 .keyword,
.highlight-5 .keyword,
.highlight-9 .keyword,
.highlight-13 .keyword {
  background-color: #dbeafe;
}
.highlight-2 .keyword,
.highlight-6 .keyword,
.highlight-10 .keyword,
.highlight-14 .keyword {
  background-color: #cffafe;
}
.highlight-3 .keyword,
.highlight-7 .keyword,
.highlight-11 .keyword,
.highlight-15 .keyword {
  background-color: #828df380;
}

.highlight-0 .keywordOccurences,
.highlight-4 .keywordOccurences,
.highlight-8 .keywordOccurences,
.highlight-12 .keywordOccurences {
  background-color: #ecfeff;
}
.highlight-1 .keywordOccurences,
.highlight-5 .keywordOccurences,
.highlight-9 .keywordOccurences,
.highlight-13 .keywordOccurences {
  background-color: #eff6ff;
}
.highlight-2 .keywordOccurences,
.highlight-6 .keywordOccurences,
.highlight-10 .keywordOccurences,
.highlight-14 .keywordOccurences {
  background-color: #ecfeff;
}
.highlight-3 .keywordOccurences,
.highlight-7 .keywordOccurences,
.highlight-11 .keywordOccurences,
.highlight-15 .keywordOccurences {
  background-color: #beb2f8;
}
