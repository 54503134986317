#tabs {
  grid-area: tabs;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  padding: 8px;
  background-color: white;
}
.tab {
  width: min-content;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  font-weight: 700;
  align-items: center;
  color: #a0a3bd;
}
.tab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 15%; /* (70% de 15% donne environ 10.5%) */
  height: 1px;
  width: 70%; 
  background-color: #ccc; 
}
.active {
  color: #170f49;
}
#barre {
  position: absolute;
  left: 0;
  height: 2px;
  background-color: #4a3aff;
  transition: left 0.25s, width 0.25s;
  width: 20%;
  margin-top: 0.37rem;
  padding-bottom: 0.1rem;
}
.nb-cvs {
  border-radius: 3px;
  background-color: white;
  margin-left: 4px;
  padding: 0 5px;
}
.tab-icon {
  color: rgba(76, 108, 226, 0.8);
  font-size: 16px; /* Adjusted for visibility */
}
.spanIcon {
  width: 25px;
  height: 25px;
  background-color: rgba(76, 108, 226, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10%;
}
