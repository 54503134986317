/* Popup.css */
.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .popup-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-buttons button:first-child {
    background-color: red; 
    color: white;
  }
  
  .popup-buttons button:last-child {
    background-color: #ccc; 
  }
  .areyousure {
    margin-top: 10px;
  }