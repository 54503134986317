#historique {
  grid-area: liste;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
#hi-entete {
  background-color: #4c6ce2;
  color: white;
  padding: 10px 35px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 59%;
  border-radius: 7px;
}
#hi-item {
  width: 59%;
  display: flex;
}
#hi-colonne {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 20px;
}
#hi-entete label {
  color: white;
}
.button-container {
  display: flex;
  align-items: center;
}
.delete-btn {
  margin-left: 8px;
  border: none;
}
#spe-fav-icon {
  color: white;
}
.recherche-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.recherche-item:hover {
  border: #4c6ce2 solid 1px;
}
.date-r {
  width: 25.5%;
}
.nom-r {
  width: 26.5%;
}
.critere-r {
  width: 44.5%;
  cursor: pointer;
}
.critere-r:hover {
  color: #4c6ce2;
}
.highlight-recherche {
  background-color: #b0caec;
  font-weight: bold;
}
.recherche-item:nth-child(odd) {
  background-color: #f2f4f8;
}
.recherche-item:nth-child(even) {
  background-color: #ffffff;
}
#pagination {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
}
#pagination span {
  cursor: pointer;
}
#pagination select {
  margin: 0 10px;
}
.previous-page {
  margin-right: 10px;
}
.b-icone-fav {
  color: #4c6ce2;
  cursor: pointer;
}
.icon-delete-search {
  color: #4c6ce2;
  cursor: pointer;
}
