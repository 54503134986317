#hi-tabs {
    grid-area: tabs;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 30px;
}

#barre {
    position: absolute;
    left: 0;
    height: 2px;
    background-color: #4a3aff;
    transition: left 0.25s, width 0.25s;
    width: 20%;
    margin-top: 0.37rem;
    padding-bottom: 0.1rem;
  }

  