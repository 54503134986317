.sample-drag-bar {
	grid-area: drag;
	flex-shrink: 0;
	width: 0px;  /* rend splitter invisible, (augmenter à 5px pour remettre la fonctionnalité) 
	cursor: col-resize;
	/* transition: background-color 0.15s 0.15s ease-in-out; */
}
#splitter {
	z-index: 10;
}
#splitter:not(.sample-drag-bar--dragging):not(.sample-drag-bar:hover) {
	background-color: #d1d5db;
}
.sample-drag-bar.sample-drag-bar--dragging,
.sample-drag-bar:hover {
	background-color: #4C6CE2;
	width: 13px;
	margin: 0 -4px;
}

.sample-drag-bar.sample-drag-bar--horizontal {
	height: 5px;
	width: 100%;
	cursor: row-resize;
}