.visualiser-cv {
    display: flex;
    white-space: nowrap;
    border: none;
    padding: 6px 13px;
    border-radius: 10px;
	color: white;
	text-decoration: none;
	width: 148;
    height: 36;
	background-color: #4C6CE2;
}
#texte-bouton {
    margin-right: 10px;
}