*, ::before, ::after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #F7F8FB;
}
button {
    border-radius: 4%;
    cursor: pointer;
}
a {
    cursor: pointer;
}
* {
    font-family: inter;
}

/* error message */
.error-message {
    color: red;
    font-weight: bold;
}