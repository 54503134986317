.monitoring-container-wrapper {
  display: flex;
  justify-content: center;
  height: 100vh;
}
.monitoring-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.recharts-wrapper {
  margin-top: 0px;
}
.pie-charts-container {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  right: 25%;
}
.graph-container {
  flex: 1 1 25%; /* Pour que chaque graphique occupe 25% de la largeur */
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.graph-title {
  margin-bottom: -3rem;
}

.graph-container.source-1,
.graph-container.source-2 {
  width: 45%;
}

.graph-container.source-3,
.graph-container.source-4 {
  width: 45%;
}
.date-filter {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.log-options {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 10px;
  z-index: 10;
}
.log-user-options {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 10px;
  z-index: 10;
}
.log-location-options {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 10px;
  z-index: 10;
}
.monitoring-filter-button {
  padding: 5px;
  color: #6b7280;
  background-color: white;
  border: solid 1px #6b7280;
  border-radius: 4px;
}
.monitoring-filter-button:hover {
  color: #4c6ce2;
  border: solid 1px #4c6ce2;
}
.p-selectbutton {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.p-button {
  padding: 5px;
  color: #6b7280;
  background-color: white;
  border: solid 1px #6b7280;
  border-radius: 4px;
}
.p-button:hover {
  background-color: #4c6ce2;
  color: white;
}
.date-filter-toggle {
  padding: 5px;
  color: #6b7280;
  background-color: white;
  border: solid 1px #6b7280;
  border-radius: 4px;
  cursor: pointer;
}
.date-filter-toggle:hover {
  color: #4c6ce2;
  border: solid 1px #4c6ce2;
}
.user-filter-toggle {
  padding: 5px;
  color: #6b7280;
  background-color: white;
  border: solid 1px #6b7280;
  border-radius: 4px;
  cursor: pointer;
}
.user-filter-toggle:hover {
  color: #4c6ce2;
  border: solid 1px #4c6ce2;
}
.location-filter-toggle {
  padding: 5px;
  color: #6b7280;
  background-color: white;
  border: solid 1px #6b7280;
  border-radius: 4px;
  cursor: pointer;
}
.location-filter-toggle:hover {
  color: #4c6ce2;
  border: solid 1px #4c6ce2;
}
.p-focus {
  background-color: white;
  color: #4c6ce2;
}
.active-button .p-highlight {
  background-color: #4c6ce2;
  color: white;
}
