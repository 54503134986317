#users {
    grid-area: liste;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}
.users-entete {
    background-color: #4C6CE2;
    padding: 10px 35px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 68%;
    border-radius: 7px;
}
.users-entete label {
    color: white;
}
.user-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.user-item:nth-child(odd) {
    background-color: #F2F4F8;
}
.user-item:nth-child(even) {
    background-color: #FFFFFF;
}
.users-list {
    width: 68%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
}
.p-user-email {
    width: 27%;
}
.p-user-firstname {
    width: 18%;
}
.p-user-lastname {
    width: 23%;
}
.p-user-role {
    width: 20%;
}
.la-user-email {
    width: 27.5%;
}
.la-user-firstname {
    width: 18%;
}
.la-user-lastname {
    width: 23%;
}
.la-user-role {
    width: 20%;
}
.la-user-action {
    width: 11%;
}
.icon-delete-user {
    color: #4C6CE2; 
    cursor: pointer;
}
.pagination {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
}
.other-page {
    border-radius: 8px;
    margin-left: 7px;
    margin-right: 7px;
    color: white;
    background-color: #4C6CE2;
    padding: 5px;
    border: none;
}
.button-container {
    margin-left: 0.9rem;
}



