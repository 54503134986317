@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");
#navigation {
  grid-area: nav;
  display: flex;
  padding: 10px 0;
  height: 40px;
  font-family: "Inter", sans-serif;
  background-color: white;
}
#wish-page {
  padding: 0 0.5rem;
  border-radius: 4px;
  background: #e0e0e0;
  border: none;
  margin: 0 0.5rem;
  outline: none;
}
#wish- #fleches-part {
  display: flex;
  gap: 10px;
}
#wish-part {
  margin-left: 11%;
  display: flex;
  height: 23px;
  width: auto;
  padding: 10px, 6px, 10px, 9px;
  gap: 5px;
  border-radius: 6px;
  color: #5a5555;
}
#wish-part-text {
  font-size: 13px;
  font-weight: normal;
  line-height: 13px;
  color: black;
  padding: 4px;
  width: auto;
}
#wish-part-text strong {
  font-weight: bold;
}
#fleches-part {
  display: flex;
  width: auto;
  margin-left: 30%;
}
#navigation .button_navigation {
  padding: 5px;
}
#button_navigation {
  border: none;
  background-color: white;
  padding-right: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: center;
  padding: 3px;
  border-radius: 4%;
  width: auto;
  height: 23px;
  margin-right: 20px;
}
#button_navigation:disabled {
  background-color: rgba(76, 108, 226, 0.3);
  color: #4c6ce2;
}
